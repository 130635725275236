












































































































































































// Core
import { Component, Prop, Watch, Mixins, PropSync } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Store
import { documentsNamespace } from '@store/documents'
import { userNamespace } from '@store/user'

// Types
import { UserActionTypes } from '@store/user/Types'
import { TaskActions, TaskMutations } from '@store/task/types'

// Interfaces
import { ITask } from '../../interfaces/Interface'
import { IDocument, IDocumentTypes } from '@store/documents/Interface'
import { DocumentsActionTypes } from '@store/documents/Types'
import { Team, TeamState } from '@store/team/interfaces'
import { ElForm } from 'element-ui/types/form'
import { Paginate, RequestParams } from '@/store/interfaces'
import { Task } from '@/store/task/interfaces'

// Modules
const NSTeam = namespace('teamModule')
const NSTask = namespace('taskModule')

interface Form {
  projectId: number
  name: string
  category: number | null
  description: string
  assigneeId: number | null
  fileList: any[]
  documentIds: number[]
  downloadDocumentIds: number[]
}

@Component({
  name: 'DialogCreateTask',

  components: {
    'v-dialog-choose-file': () => import('@/components/dialogs/DialogChooseFile.vue'),
  },
})
export default class DialogCreateTaskComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @documentsNamespace.State('documentTypes')
  private documentType!: IDocumentTypes

  @NSTeam.State((state: TeamState) => state.team.data)
  private team!: Team[]

  @NSTask.Action(TaskActions.A_FETCH_TASKS)
  private fetchTasks!: (params?: RequestParams) => Promise<Paginate<Task>>

  @NSTask.Mutation(TaskMutations.M_CLEAR_TASKS)
  private clearTasks!: () => void

  @NSTask.Action(TaskActions.A_CREATE_TASK)
  private createTask!: (form: Form) => Promise<any>

  private isExpectation: boolean = false

  private visibleDialogChooseFile = false

  private form: Form = {
    projectId: this.selectedProject,
    name: '',
    category: null,
    description: '',
    assigneeId: null,
    fileList: [],
    documentIds: [],
    downloadDocumentIds: [],
  }

  private rules: object = {
    name: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
    category: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
    assigneeId: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
    description: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
  }

  private created() {
    this.form.projectId = this.selectedProject
  }

  private changeFileList(file, fileList) {
    this.form.fileList = fileList
  }

  private deleteDocument(uid) {
    const index = this.form.fileList.findIndex(file => file.uid === uid)
    this.form.fileList.splice(index, 1)
  }

  private handleCreateTask() {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        this.isExpectation = true
        this.createTask(this.form)
          .then(() => {
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })

            this.isExpectation = false
            this._visible = false

            this.clearTasks()
            this.handleClose()
            this.fetchTasks({
              projectId: this.selectedProject,
            })
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response?.data.message ?? 'Что-то пошло не так... Обратитесь в тех. поддержку',
            })
          })
      }
    })
  }

  private handleChooseFile(docs: number[]) {
    this.form.downloadDocumentIds = docs
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    this._visible = false
    this.form.documentIds = []

    form.resetFields()
  }
}
